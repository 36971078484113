import React from "react";
import Spinner from "../components/spinner/Spinner";

export default function Shop() {
  if (typeof window === "undefined") {
    return null;
  }

  return <Spinner enabled={true} />;
}
