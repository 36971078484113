import React from "react";
import PropTypes from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";

const Spinner = ({ enabled = false }) => {
  const spinnerActive = useSelector((state) => state.ui.spinnerActive);

  return (
    <div
      style={{
        width: "57px",
        position: "absolute",
        top: "50%",
        left: "calc(50% - 28.5px)",
      }}
    >
      <BeatLoader
        size={15}
        color="rgba(55, 85, 66, 1)"
        loading={spinnerActive || enabled === true}
      />
    </div>
  );
};

Spinner.propTypes = {
  enabled: PropTypes.bool,
};

Spinner.defaultProps = {};

export default Spinner;
